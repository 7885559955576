


export function filterActiveList(list = [],active = []) {
    let newList = []
    if (active){
        active.forEach(item=>{
            let key = list.findIndex(a=>a.id == item)
            if (key >= 0)newList.push(list[key])
        })
    }
    return newList
}
